import React, { FC, useState, useRef, useEffect } from 'react';
import { Grid, Card, Icon, Button, Dimmer, Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
    ORGDataTable,
    IORGDataTableColumn,
} from 'src/components/common/CommonUI/organisms/ORGDataTable/ORGDataTable.component';
import { ATMPopover } from 'src/components/common/CommonUI/atoms/ATMPopover/ATMPopover.component';
import { ATMIcon } from 'src/components/common/CommonUI/atoms/ATMIcon/ATMIcon.component';
import ConfirmModal from '../../../common/ConfirmModal/ConfirmModal.component';
import ApproveModal from '../../../common/ApproveModal/ApproveModal.component';
import AcceptOrDeclineModal from '../../../common/AcceptOrDeclineModal/AcceptOrDeclineModal.component';
import { useNotificationDispatch } from '../../../../contexts/notification.context';
import { useAuthenticationState } from 'src/contexts/authentication.context';
import { ApplicationDataType } from '../../../../types/landing.types';
import Lang from '../../../../libraries/language';
import styles from '../Private.module.scss';
import ViewOrEditApplication from '../Application/ViewOrEditApplication.component';
import CancelApplication from '../Application/CancelApplication.component';
import EditApplication from '../Application/EditApplication.component';
import { ViewOrEditDataTypes } from '../../../../types/ViewOrEdit.types';
import {
    useFetchAllUserApplications,
    useConfirmApplication, useDeclineApplication, useAcceptFundApplication
} from '../../../../hooks/useApplicationHooks';
import { useFetchActiveProgramSummary } from '../../../../hooks/useProgramHooks';
import { actionsPermissions } from '../../../../constants/landing.constants';
import ProgramPDF from '../Program/ProgramPDF.component';
import { CreateApplicationRequestBodyType } from 'src/types/editApplication.types';
import { encryptId } from 'src/utils/common.utils';

const Landing: FC = () => {
    const { authenticatedUserEmail } = useAuthenticationState();
    const [openViewOrEditApplicationPopup, setViewOrEditApplicationPopup] =
        useState(false);
    const [cancelApplicationPopup, setCancelApplicationPopup] = useState(false);
    const [openEditApplicationPopup, setEditApplicationPopup] = useState(false);

    const [isModifyAndAccept, setIsModifyAndAccept] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
    const [acceptOrDecline, setAcceptOrDecline] = useState<
        'accept' | 'decline'
    >('accept');
    const [openAcceptOrDeclineModal, setOpenAcceptOrDeclineModal] =
        useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = useState<string>('');
    const [selectedRecordValues, setSelectedRecordValues] = useState<any>({});
    const navigate = useNavigate();
    const notificationDispatch = useNotificationDispatch();

    const [enableFetchApplication, setEnableFetchApplication] =
        useState<boolean>(true);
    const [enableFetchProgram, setEnableFetchProgram] = useState<boolean>(true);
    const {
        data: allUserApplications,
        error: allUserApplicationsError,
        isFetching: allUserApplicationsLoading,
    } = useFetchAllUserApplications(enableFetchApplication);
    const [renderPDFComponent, setRenderPDFComponent] = useState(false);
    

    useEffect(() => {
        if (allUserApplicationsError) {
            notificationDispatch({
                type: 'ERROR',
                payload: Lang.MSG_ERROR_500,
            });
            setEnableFetchApplication(false);
        }
    }, [allUserApplicationsError]);

    useEffect(() => {
        if (allUserApplications) {
            allUserApplications.data.map((record) => {
                record['fuelType'] = Number(record.numberOfRngTrucks) > 0 ? 'RNG' : 'Hydrogen';
                record['numberOfTrucks'] = Number(record.numberOfRngTrucks) > 0 ? record?.numberOfRngTrucks : record?.numberOfHydrogenTrucks;
            })
            setEnableFetchApplication(false);
        }
    }, [allUserApplications]);

    const {
        data: activeProgramSummary,
        error: activeProgramSummaryError,
        isFetching: activeProgramSummaryLoading,
    } = useFetchActiveProgramSummary(enableFetchProgram);

    useEffect(() => {
        if (activeProgramSummary) {
            setEnableFetchProgram(false);
        }

        if (activeProgramSummaryError) {
            notificationDispatch({
                type: 'ERROR',
                payload: Lang.MSG_ERROR_500,
            });
            setEnableFetchProgram(false);
        }
    }, [activeProgramSummary, activeProgramSummaryError]);

    const {
        mutate: confirmApplicationService,
        isLoading: confirmApplicationLoading,
        isError: confirmApplicationError,
        isSuccess: confirmApplicationSuccess,
    } = useConfirmApplication();

    const {
        mutate: declineApplicationService,
        isLoading: declineApplicationLoading,
        isError: declineApplicationError,
        isSuccess: declineApplicationSuccess,
    } = useDeclineApplication();

    const {
        mutate: acceptFundApplicationService,
        isLoading: acceptFundApplicationLoading,
        isError: acceptFundApplicationError,
        isSuccess: acceptFundApplicationSuccess,
    } = useAcceptFundApplication();

    useEffect(() => {
        if (confirmApplicationSuccess) {
            setEnableFetchApplication(true);
            notificationDispatch({
                type: 'SUCCESS',
                payload: `${Lang.MSG_APPLICATION_CONFIRMATION[0]}: ${selectedRecord} ${Lang.MSG_APPLICATION_CONFIRMATION[1]}`,
            });

            setOpenConfirmModal(false);
            setSelectedRecord('');
        }
    }, [confirmApplicationSuccess]);

    useEffect(() => {
        if (acceptFundApplicationSuccess) {
            notificationDispatch({
                type: 'SUCCESS',
                payload: `${Lang.MSG_ACCEPT_APPLICATION[0]}: ${selectedRecord} ${Lang.MSG_ACCEPT_APPLICATION[1]}`,
            });
            setOpenAcceptOrDeclineModal(false);
            setSelectedRecord('');
        }
    }, [acceptFundApplicationSuccess])

    useEffect(() => {
        if (declineApplicationSuccess) {
            notificationDispatch({
                type: 'SUCCESS',
                payload: `${Lang.MSG_DECLINE_APPLICATION[0]}: ${selectedRecord} ${Lang.MSG_DECLINE_APPLICATION[1]}`,
            });

            setOpenAcceptOrDeclineModal(false);
            setSelectedRecord('');
        }
    }, [declineApplicationSuccess])

    const applicationToView = useRef({} as ViewOrEditDataTypes);
    const applicationToCreatePDF = useRef({} as CreateApplicationRequestBodyType);

    const renderPDF = (updatedData) => {
        setRenderPDFComponent(true);
        const app = allUserApplications?.data.find(
            (application) => application.id === updatedData.applicationNumber
        );
        applicationToCreatePDF.current = {
            solicitationMasterId: Number(app.solicitationMasterId),
            businessName: app.businessName,
            contactPersonName: app.contactPersonName,
            contactPersonEmail: app.contactPersonEmail,
            phoneNumber: app.phoneNumber,
            address: app.address,
            zip: app.zip,
            city: app.city,
            state: app.state,
            currentNumberOfTrucksInFleet: Number(app.currentNumberOfTrucksInFleet),
            numberOfRngTrucks: updatedData.numberOfRngTrucks,
            numberOfHydrogenTrucks: updatedData.numberOfHydrogenTrucks,
            estimatedDateOfPurchase: app.estimatedDateOfPurchase,
            estimatedDateOfDelivery: app.estimatedDateOfDelivery,
            signatoryName: app.signatoryName,
            title: app.title,
            applicationEsignatureDate: app.applicationEsignatureDate,
        } as CreateApplicationRequestBodyType;
    };

    const closeEditApplicationPopup = () => { 
      setRenderPDFComponent(false);
      setEditApplicationPopup(false);
    }

    const handleViewApplication = (record) => {
        record['status'] = record.applicationStatus;
        record['submissionDate'] = moment(record.applicationDate).format('MMMM DD, YYYY');
        (record['verificationDate'] = record?.applicationEsignatureDate
            ? moment(record.applicationEsignatureDate).format('MMMM DD, YYYY')
            : ''),
            (record['contactName'] = record.contactPersonName);
        record['contactEmail'] = record.contactPersonEmail;
        record['phoneNumber'] = record.phoneNumber;
        record['address'] = record.address;
        record['city'] = record.city;
        record['state'] = record.state;
        record['zipCode'] = record.zip;
        if (record.currentNumberOfTrucksInFleet < 9) {
            record['truckNumber'] = '9 or less';
        }
        if (record.currentNumberOfTrucksInFleet > 9) {
            record['truckNumber'] = '10 or more';
        }

        record['estDeliveryDate'] = moment(record.estimatedDateOfDelivery).format('MMMM DD, YYYY');
        record['estPurchaseDate'] = moment(record.estimatedDateOfPurchase).format('MMMM DD, YYYY');
        record['rngTrucks'] = Number(record.numberOfRngTrucks);
        record['hydrogenTrucks'] = Number(record.numberOfHydrogenTrucks);
        record['estimatedFund'] = record.estimatedFund;
        record['appovedFund'] = record.fundAllocated;
        record['legalBusinessName'] = record.businessName;
        record['designation'] = record.title;
        applicationToView.current = record;
        setViewOrEditApplicationPopup(true);
    };

    const EditApplicationHandler = (record, accept = false) => {
        record['rngTrucks'] = Number(record.numberOfRngTrucks);
        record['hydrogenTrucks'] = Number(record.numberOfHydrogenTrucks);
        applicationToView.current = record;
        setIsModifyAndAccept(accept);

        setEditApplicationPopup(true);
    };

    const onCancelApplicationHandler = (record) => {
        applicationToView.current = record;
        setCancelApplicationPopup(true);
    };

    const mainHandler = () => {
        setEditApplicationPopup(true);
        setViewOrEditApplicationPopup(false);
    };

    const onClickApplyForProgramHandler = () => {
        navigate('/apply-for-program');
    };

    const confirmApplicationHandler = async () => { 
        const applicationId = await encryptId(selectedRecord);
        await confirmApplicationService({ applicationNumber: applicationId, updatedBy: authenticatedUserEmail });
    };

    const openConfirmApplicationModalHandler = (
        applicationNumber: string | number
    ) => {
        setSelectedRecord(applicationNumber.toString());
        setOpenConfirmModal(true);
    };

    const openAcceptOrDeclineModalHandler = (
        applicationState: 'accept' | 'decline'
    ) => {
        setOpenApproveModal(false);
        setAcceptOrDecline(applicationState);
        setOpenAcceptOrDeclineModal(true);
    };

    const acceptApplicationHandler = async () => {
         acceptFundApplicationService({ applicationNumber: selectedRecord, updatedBy: authenticatedUserEmail });
    };

    const declineApplicationHandler = async () => {
         declineApplicationService({ applicationNumber: selectedRecord, updatedBy: authenticatedUserEmail });
    };

    const openModifyAndAcceptModalHandler = () => {
        setOpenApproveModal(false);
        EditApplicationHandler(selectedRecordValues, true);
    };

    const modifyAndAcceptHandler = () => {
        setSelectedRecordValues({});
        setSelectedRecord('');
    };

    const openApproveFundingModalHandler = (record: ApplicationDataType) => {
        setSelectedRecord(record.id.toString());
        setSelectedRecordValues(record);
        setOpenApproveModal(true);
    };

    const handleClosePopup = () => {
        applicationToView.current = {} as ViewOrEditDataTypes;
        setEditApplicationPopup(false);
        setViewOrEditApplicationPopup(false);
        setCancelApplicationPopup(false);
    };

    const columns: IORGDataTableColumn<ApplicationDataType>[] = [
        {
            title: 'Application',
            index: 'id',
            visibilityToggle: false,
            render: (_, record) => {
                return (
                    <>
                        <a
                            className="sempraPrimaryLink"
                            onClick={() => handleViewApplication(record)}
                        >
                            {record.id}
                        </a>
                    </>
                );
            },
        },
        {
            title: 'Program Name',
            index: 'programName',
        },
        {
            title: 'Fuel Type',
            index: 'fuelType',
            render: (_, record) => (
                <div>
                    <div>
                       {record.fuelType}
                    </div>
                </div>
            ),
        },
        {
            title: '# of Trucks',
            index: 'numberOfTrucks',
            render: (_, record) => {
                return (
                    <div>
                        <div>
                            {record.numberOfTrucks}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Submission Date',
            index: 'applicationDate',
            render: (_, record) => (
                <div>
                    <div>
                        {record?.applicationDate
                            ? moment(record.applicationDate).format(
                                'MM-DD-YYYY'
                            )
                            : '-'}
                    </div>
                </div>
            ),
        },
        {
            title: 'Verification Date',
            index: 'applicationEsignatureDate',
            render: (_, record) => (
                <div>
                    <div>
                        {record?.applicationEsignatureDate
                            ? moment(record.applicationEsignatureDate).format('MM-DD-YYYY')
                            : '-'}
                    </div>
                </div>
            ),
        },
        {
            title: 'Application Status',
            index: 'applicationStatus',
            render: (_, record) => (
                <div>
                    <div>
                        {record?.applicationStatus
                            ? record?.applicationStatus
                            : '-'}
                    </div>
                </div>
            ),
        },
        {
            title: 'Actions',
            index: 'actions',
            visibilityToggle: false,
            render: (_, record) => (
                <>
                    <ATMPopover
                        trigger={
                            <ATMIcon
                                link
                                role="button"
                                name="eye"
                                className={styles.tableIcons}
                                onClick={() => handleViewApplication(record)}
                            />
                        }
                        on="hover"
                        size="mini"
                        content={Lang.LBL_VIEW_APPLICATION}
                    />

                    {actionsPermissions.editAction.includes(
                        record.applicationStatusId
                    ) &&
                        activeProgramSummary &&
                        activeProgramSummary.data.id ===
                            record.solicitationMasterId && (
                            <ATMPopover
                                trigger={
                                    <ATMIcon
                                        link
                                        role="button"
                                        name="edit outline"
                                        className={styles.tableIcons}
                                        onClick={() =>
                                            EditApplicationHandler(record)
                                        }
                                    />
                                }
                                on="hover"
                                size="mini"
                                content={Lang.LBL_EDIT_APPLICATION}
                            />
                        )}

                    {actionsPermissions.cancelAction.includes(
                        record.applicationStatusId
                    ) && (
                            <ATMPopover
                                trigger={
                                    <ATMIcon
                                        link
                                        role="button"
                                        name="window close outline"
                                        className={styles.tableIcons}
                                        onClick={() =>
                                            onCancelApplicationHandler(record)
                                        }
                                    />
                                }
                                on="hover"
                                size="mini"
                                content={Lang.LBL_CANCEL_APPLICATION}
                            />
                        )}

                    {actionsPermissions.confirmAction.includes(
                        record.applicationStatusId
                    ) &&
                        activeProgramSummary &&
                        activeProgramSummary.data.id ===
                        record.solicitationMasterId && (
                            <ATMPopover
                                trigger={
                                    <ATMIcon
                                        link
                                        role="button"
                                        name="pencil alternate"
                                        className={styles.tableIcons}
                                        onClick={() =>
                                            openConfirmApplicationModalHandler(
                                                record.id
                                            )
                                        }
                                    />
                                }
                                on="hover"
                                size="mini"
                                content={Lang.LBL_CONFIRM_APPLICATION}
                            />
                        )}

                    {actionsPermissions.approveAction.includes(
                        record.applicationStatusId
                    ) &&
                        activeProgramSummary &&
                        activeProgramSummary.data.id ===
                        record.solicitationMasterId && (
                            <ATMPopover
                                trigger={
                                    <ATMIcon
                                        link
                                        role="button"
                                        name="check circle"
                                        className={styles.tableIcons}
                                        onClick={() =>
                                            openApproveFundingModalHandler(
                                                record
                                            )
                                        }
                                    />
                                }
                                on="hover"
                                size="mini"
                                content={Lang.LBL_ACCEPT_FUNDING}
                            />
                        )}
                </>
            ),
        },
    ];

    const disableApplyProgramButton = () => {
        return (
            !activeProgramSummary?.data.displaySolicitation ||
            moment(activeProgramSummary?.data.endDate).format('YYYY-MM-DD') <
                moment().format('YYYY-MM-DD')
        );
    };
    return (
        <>
            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                onConfirm={confirmApplicationHandler}
                hasError={confirmApplicationError}
                isLoading={confirmApplicationLoading}
            />

            <ApproveModal
                open={openApproveModal}
                setOpen={setOpenApproveModal}
                onAccept={() => openAcceptOrDeclineModalHandler('accept')}
                onDecline={() => openAcceptOrDeclineModalHandler('decline')}
                onModify={() => openModifyAndAcceptModalHandler()}
            />

            {acceptOrDecline === 'accept' ? (
                <AcceptOrDeclineModal
                    header={Lang.TTL_FA_ACCEPT}
                    content={
                        <p>
                            By clicking <b>{"'Yes'"}</b>, you acknowledge to
                            receive the Fuel Card and submit the relevant
                            purchase and delivery of truck documents.
                        </p>
                    }
                    open={openAcceptOrDeclineModal}
                    setOpen={setOpenAcceptOrDeclineModal}
                    onConfirm={acceptApplicationHandler}
                    hasError={acceptFundApplicationError}
                    isLoading={acceptFundApplicationLoading}
                />
            ) : (
                <AcceptOrDeclineModal
                    header={Lang.TTL_FA_DECLINE}
                    content={
                        <p>
                            By clicking <b>{"'Yes'"}</b>, you acknowledge to
                            decline the Fuel Card and withdraw from the
                            incentive program.
                        </p>
                    }
                    open={openAcceptOrDeclineModal}
                    setOpen={setOpenAcceptOrDeclineModal}
                    onConfirm={declineApplicationHandler}
                    hasError={declineApplicationError}
                    isLoading={declineApplicationLoading}
                />
            )}

            <div className={styles.section}>
                <Card fluid className={styles.sectionCard}>
                    <Card.Content className={styles.sectionContent}>
                        <Grid columns={2}>
                            <Grid.Column className={styles.icon}>
                                <Icon name="info circle" />
                            </Grid.Column>

                            <Grid.Column width={15} className={styles.content}>
                                <h2>{Lang.TTL_LANDING_FCPP}</h2>
                                <div className={styles['fontSize12']}>
                                    <p>
                                        {
                                            Lang.NT_LANDING_PROV_POLICY_PART1.split(
                                                '<sup>1</sup>'
                                            )[0]
                                        }
                                        <sup>1 </sup>
                                        {
                                            Lang.NT_LANDING_PROV_POLICY_PART2.split(
                                                '<sup>2</sup>'
                                            )[0]
                                        }
                                        <sup>2 </sup>
                                        {
                                            Lang.NT_LANDING_PROV_POLICY_PART2.split(
                                                '<sup>2</sup>'
                                            )[1]
                                        }
                                    </p>
                                    <div className={styles.privateThinLine} />
                                    <p>
                                        <sup
                                            className={
                                                styles.privateSuperScript
                                            }
                                        >
                                            1
                                        </sup>
                                        {Lang.NT_PR_FOOTER[0]}
                                    </p>
                                    <p>
                                        <sup
                                            className={
                                                styles.privateSuperScript
                                            }
                                        >
                                            2
                                        </sup>
                                        {Lang.NT_PR_FOOTER[1]}
                                        <a
                                            href={Lang.NT_PR_FOOTER[2]}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {
                                                Lang.LBL_MEDIUM_AND_HEAVY_DUTY_ZERO_EMISSION_VEHICLES
                                            }
                                        </a>
                                    </p>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Card.Content>
                </Card>

                <Card fluid className={styles.sectionCard}>
                    {!!activeProgramSummaryLoading && (
                        <Dimmer active inverted>
                            <Loader size="medium">Loading</Loader>
                        </Dimmer>
                    )}

                    <Card.Content className={styles.sectionContent}>
                        {activeProgramSummary ? (
                            <Grid columns={3}>
                                <Grid.Column className={styles.icon}>
                                    <Icon name="info circle" />
                                </Grid.Column>

                                <Grid.Column
                                    computer={11}
                                    mobile={10}
                                    className={styles.content}
                                >
                                    <h2>
                                        {
                                            activeProgramSummary?.data
                                                ?.programName
                                        }
                                    </h2>
                                    <p>
                                        {activeProgramSummary?.data
                                            ?.solicitationNotes
                                            ? activeProgramSummary?.data
                                                  ?.solicitationNotes
                                            : ''}
                                    </p>
                                </Grid.Column>

                                <Grid.Column
                                    computer={4}
                                    mobile={5}
                                    // className={styles.buttonColumn}
                                    textAlign="right"
                                >
                                    {disableApplyProgramButton() ? (
                                        <ATMPopover
                                            position="top center"
                                            trigger={
                                                <div>
                                                    <Button
                                                        className={
                                                            styles.sempraButton
                                                        }
                                                        onClick={
                                                            onClickApplyForProgramHandler
                                                        }
                                                        disabled
                                                    >
                                                        {Lang.LBL_BTN_APPLY}
                                                    </Button>
                                                </div>
                                            }
                                            content={
                                                Lang.MSG_NO_FURTHER_APPLICATIONS_ARE_ACCEPTING
                                            }
                                            // size="mini"
                                        />
                                    ) : (
                                        <Button
                                            className={styles.sempraButton}
                                            onClick={
                                                onClickApplyForProgramHandler
                                            }
                                        >
                                            {Lang.LBL_BTN_APPLY}
                                        </Button>
                                    )}
                                </Grid.Column>
                            </Grid>
                        ) : (
                            <span>{Lang.MSG_NO_ACTIVE_PROGRAM}</span>
                        )}
                    </Card.Content>
                </Card>

                <Card fluid className={styles.sectionCard}>
                    {!!allUserApplicationsLoading && (
                        <Dimmer active inverted>
                            <Loader size="large">Loading</Loader>
                        </Dimmer>
                    )}
                    <Card.Content>
                        <Grid columns={2}>
                            <Grid.Column className={styles.emptyColumn} />
                            <Grid.Column width={15} className={styles.content}>
                                <h2>{Lang.TTL_LANDING_PAA}</h2>
                                <ORGDataTable
                                    compact
                                    counter
                                    sortable
                                    singleLine
                                    enableScroll
                                    showPagination
                                    celled={false}
                                    fixedColumns="last"
                                    allButtonSize="small"
                                    columns={columns}
                                    data={allUserApplications?.data || []}
                                />
                            </Grid.Column>
                        </Grid>
                    </Card.Content>
                </Card>

                <div>
                    {openViewOrEditApplicationPopup ? (
                        <ViewOrEditApplication
                            application={applicationToView.current}
                            setViewOrEditApplicationPopup={
                                setViewOrEditApplicationPopup
                            }
                            mainHandler={mainHandler}
                            activeProgram={activeProgramSummary?.data}
                        />
                    ) : null}
                </div>
                <div>
                    {cancelApplicationPopup ? (
                        <CancelApplication
                            application={applicationToView.current}
                            handleClosePopup={handleClosePopup}
                        />
                    ) : null}
                </div>

                <div>
                    {openEditApplicationPopup ? (
                        <EditApplication
                            application={applicationToView.current}
                            closeEditApplicationPopup={
                                closeEditApplicationPopup
                            }
                            willAccept={isModifyAndAccept}
                            acceptHandler={modifyAndAcceptHandler}
                            renderPDFHandler={renderPDF}
                        />
                    ) : null}
                    {renderPDFComponent ? (
                        <div id="content-id" className="content-id">
                            <div
                                style={{
                                    width: '840px',
                                    padding: '20px 40px 40px',
                                    height: '2000px',
                                }}
                            >
                                <ProgramPDF
                                    application={applicationToCreatePDF.current}
                                    isEdit={true}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default Landing;
